<template>
  <transition name="fade">
    <div
      class="modal-backdrop"
      v-if="isOpen"
      :class="{ open: isOpen }"
      @click="$emit('on-close')"
    >
      <div :class="{ draggable: isDraggable }" ref="draggableContainer">
        <div
          class="modal-dialog"
          :class="{
            open: isOpen,
            sm: size === 'sm',
            md: size === 'md',
            lg: size === 'lg',
            xl: size === 'xl',
          }"
          @click.stop
        >
          <div
            class="modal-header"
            @mousedown="dragMouseDown"
            :class="{ grabbing: isGrabbing }"
          >
            <button class="modal-close" @click="$emit('on-close', true)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
            <div class="modal-title" v-if="title">{{ title }}</div>
          </div>

          <div class="modal-body">
            <slot />
          </div>
          <div class="modal-footer">
            <div>
              <Button buttonType="grey" @click="$emit('on-close', true)">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
  },
  props: {
    isOpen: Boolean,
    title: String,
    size: {
      type: String,
      default: "sm",
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isGrabbing: false,
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
    },
  }),
  methods: {
    dragMouseDown(event) {
      event = event || window.event;
      event.preventDefault();
      if (this.isDraggable) {
        this.isGrabbing = true;
      }
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event = event || window.event;
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        1 +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        1 +
        "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.isGrabbing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.grabbing {
  cursor: -webkit-move;
  cursor: move;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter .modal-dialog,
.fade-leave-to .modal-dialog {
  transform: translateY(-20%);
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9;
  .sm {
    width: 30rem;
  }
  .md {
    width: 50rem;
  }
  .lg {
    width: 70rem;
  }
  .xl {
    width: 100rem;
  }
  .modal-dialog {
    background: rgb(255, 255, 255);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.3rem;
    transition: 0.5s;
    position: relative;
    .modal-header {
      background: #045781;
      color: #fff;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    .modal-close {
      background: none;
      border: none;
      position: absolute;
      top: 0.8rem;
      right: 0.9rem;
      outline: none;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #e0dcdc;
        &:hover {
          fill: rgb(150, 150, 150);
        }
      }
    }
    .modal-title {
      margin-bottom: 1rem;
      color: #fff;
      padding: 5px 15px 10px;
      margin: 0;
      font-size: 1.2rem;
    }

    .modal-body {
      padding: 1.2em;
      max-height: 440px;
    }
    .modal-footer {
      padding: 10px 15px;
      border-top: 1px solid #ddd;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }
}
.draggable {
  position: absolute;
  z-index: 9;
}
</style>
