var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "modal-backdrop",
            class: { open: _vm.isOpen },
            on: {
              click: function($event) {
                return _vm.$emit("on-close")
              }
            }
          },
          [
            _c(
              "div",
              {
                ref: "draggableContainer",
                class: { draggable: _vm.isDraggable }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog",
                    class: {
                      open: _vm.isOpen,
                      sm: _vm.size === "sm",
                      md: _vm.size === "md",
                      lg: _vm.size === "lg",
                      xl: _vm.size === "xl"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-header",
                        class: { grabbing: _vm.isGrabbing },
                        on: { mousedown: _vm.dragMouseDown }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "modal-close",
                            on: {
                              click: function($event) {
                                return _vm.$emit("on-close", true)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                                  }
                                }),
                                _c("path", {
                                  attrs: { d: "M0 0h24v24H0z", fill: "none" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm.title
                          ? _c("div", { staticClass: "modal-title" }, [
                              _vm._v(_vm._s(_vm.title))
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [_vm._t("default")],
                      2
                    ),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "Button",
                            {
                              attrs: { buttonType: "grey" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("on-close", true)
                                }
                              }
                            },
                            [_vm._v(" Close ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }